.loading-blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent white background */
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px); /* Adjust the blur amount as needed */
    z-index: 999; /* Ensure it appears above other content */
  }
  
  .loading-content {
    text-align: center;
  }