.footer {
  margin: 5px 0;
  bottom: 0;
  width: 100%;

  &-content {
    margin: 0 50px;
    display: flex;
    justify-content: center;
    padding: 16px 0;
    color: #4a4a4a;

  }
}
