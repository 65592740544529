.header {
  display: flex;
  align-items: center;
  padding: 5px 16px;
  justify-content: space-between;
  position: fixed;
  height: 67px;
  background: white;
  width: 100%;
  top: 0;
  z-index: 2;

  img {
    width: 25px;
  }

  &--left {
    display: flex;
    cursor: pointer;
  }

  &--right {
    display: none;
  }

  &--right {
    font-weight: bold;
  }

  .button-contact {
    margin-right: 22px;
  }

  .button-access {
    display: flex;
    align-items: center;
    color: #338dbf;

    svg {
      margin-left: 5px;
    }
  }

  .logo-name {
    font-family: "Batangas";
    text-transform: uppercase;
    font-size: 26px;
    margin-left: 6px;
    color: #338dbf;
  }
}

@media (min-width: 769px) {
  .header {
    padding: 5px 50px;
    height: 75px;

    img {
      width: 35px;
    }

    .logo-name {
      font-size: 32px;
    }

    &--right {
      display: flex;
      font-weight: bold;
    }
  }
}
