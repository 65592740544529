.sidebar {
  background-color: #f0f6fb;
  border-radius: 0 40px 40px 0;
  min-width: 170px;
  height: 100%;
  width: 100%;

  .logo-container {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 160px;
      margin-top: 12px;
    }
  }

  & .chakra-divider.sidebar-divider {
    border-color: #d6dae6;
    width: 70%;
    margin: 0 auto;
  }

  .sidebar-actions {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      width: 150px;
      text-align: center;
      border-radius: 5px;
      font-weight: bold;
      color: #006fb7;
      margin-top: 8px;
      display: flex;
      align-items: center;
      transition: all ease 0.3s;
      padding: 10px;
      font-size: 14px;

      &:hover {
        background-color: #a5cbe140;
      }

      svg {
        font-size: 18px;
        margin-right: 7px;
      }
    }
  }
}
