.users-filter-bar {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto auto;
  grid-column-gap: 10px;

  .search-button {
    background-color: #006fb7;
    color: white;

    svg {
      margin-right: 7px;
    }
  }
}

.third-row-heading {
  display: flex;
  justify-content: space-between;
}

.results-table {
  margin-top: 30px;

  .table-row {
    text-align: center;

    svg {
      margin-left: 7px;
      cursor: pointer;
    }
  }
}
