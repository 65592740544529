.contact-form {
  .chakra-form-control:not(:first-child) {
    margin-top: 20px;
  }

  .chakra-button {
    margin-top: 20px;
  }

  .chakra-card__body {
    padding: 35px;
  }
}
