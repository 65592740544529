.card-section {
  margin: 45px 25px 20px;

  &--cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .chakra-divider {
      color: #e9e9e9;
    }
  }

  &--heading {
    text-align: center;
    cursor: default;
  }

  .chakra-heading.card-section--heading {
    font-family: "DM Sans", sans-serif;
  }

  &--card {
    margin: 25px;
    height: auto;
    padding: 10px;
    transition: all 300ms ease-out;
    cursor: default;

    .card-header {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .chakra-card__header.card-header {
      padding: 6px 6px 20px;
    }
  }
}

@media (min-width: 426px) {
  .card-section--card {
    width: 400px;
  }
}

@media (min-width: 769px) {
  .card-section {
    padding: 40px 25px 50px;

    &--cards-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: unset;
      max-width: 1280px;
      margin: 0 auto;
    }

    &--heading {
      margin-bottom: 45px;
    }

    
    &--card {
      width: 31%;
      height: auto;
      padding: 10px;
      transition: all 300ms ease-out;
      margin: 0;

      .card-body {
        text-align: left;
      }

      &:hover {
        transform: translate(0px, 0px) scale(1.05, 1.05);
        box-shadow: 0px 0px 7px 0px #3e8dc7;
      }
    }
  }
}
