.note-modal-content {
  .options-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .spinner {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
