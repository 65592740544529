
.banner {
  width: 100vw;
  //display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "DM Sans", sans-serif;
  margin-top: 75px;

  &-bg {
    height: auto;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 35px;

    &--right {
      margin-top: 30px;
    }

    &--left {
      width: 100%
    }
  }

  &-title {
    color: white;
    z-index: 1;
    font-size: 40px;
    max-width: 700px;
    font-weight: 800;
    text-align: left;
    line-height: 1.25;
    padding-bottom: 10px;
  }

  &-description {
    color: white;
    font-size: 20px;
    //max-width: 580px;
    line-height: 1.25;
    text-align: left;
  }

  img {
    width: 100vw;
  }

  &-form-input {
    label {
      padding-top: 30px;
    }
  }

  .contact-form {
    .chakra-card__body {
      padding: 18px;
    }

    .chakra-button {
      background: #338dbf;
      width: 100%;
    }

    .chakra-heading {
      margin: 2px;
    }

    .chakra-text {
      margin: 0;
    }
  }
}

@media (min-width: 769px) {
  .banner {
    &-bg {
      height: 100%;
      text-align: left;
      padding: 40px 40px;
      display: block;
      flex-direction: row;
      text-align: center;
      justify-content: space-between;
      background-size: cover;

      &--right {
        width: 50%;
        margin-top: 0;
        padding-left: 30px;

        .chakra-card {
          max-width: 465px;
          margin: 0 auto;
        }
      }

      &--left {
        width: 70%;
        margin-left: 2%;
      }
    }

    &-title {
      line-height: 1.6;
    }

    &-description {
      line-height: 1.5;
    }

    .contact-form {
      .chakra-button {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1024px) {
  .banner-title {
    font-size: 56px;
  }

  .banner-description {
    font-size: 28px;
  }

  .banner-bg--left {
    margin-left: 5%;
  }
}
