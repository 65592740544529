.clients-table {
  h6 {
    font-family: "Montserrat", sans-serif;
    color: #006fb7;
    margin-top: 20px;
  }

  
}

.clients-page {
  .container {
    margin: 20px 30px 0;

  }
}