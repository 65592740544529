.free-load-modal {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  padding: 16px;

  .row {
    display: flex;
    align-items: end;
    gap: 16px;
    margin-bottom: 16px;
  }

  .patent-input,
  .type-select,
  .guide-input,
  .weight-input {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .destinations-select-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  .second-heading {
    flex: 1;
  }

  .filtered-select-and-button {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  .filtered-select {
    flex-grow: 1;
    margin-right: 16px;
  }

  .Divider {
    margin-bottom: 26px;
    margin-top: 26px;
  }

  .footer {
    gap: 20px
  }
}
