.clients-filter-bar {
  margin-top: 20px;
  display: grid;
  // grid-template-columns: repeat(2, 1fr) auto auto auto auto auto repeat(2, 1fr);
  grid-template-columns: repeat(4, 1fr) auto auto;
  grid-column-gap: 10px;

  .search-button {
    background-color: #006fb7;
    color: white;

    svg {
      margin-right: 7px;
    }
  }
}

.results-table {
  margin-top: 30px;

  .table-row {
    text-align: center;

    svg {
      margin-left: 7px;
      cursor: pointer;
    }

    .menu-button {
      margin-left: 0px;
    }
  }
}
