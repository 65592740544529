// Components
@import "../../components/Header/header.scss";
@import "../../components/Banner/banner.scss";
@import "../../components/Footer/footer.scss";
@import "../../components/Cards/cards.scss";
@import "../../components/ImageText/image-text.scss";
@import "../../components/TextSection/text-section.scss";
@import "../../components/TitleBodySection/title-body-section.scss";
@import "../../components/ContactForm/index.scss";
@import "../../components/Layout/index.scss";
@import "../../components/ClientTable/index.scss";
@import "../../components/UserTable/index.scss";
@import "../../components/LoadingBlur/loadingBlur.scss";
@import "../../components/ContainerModal/containerModal.scss";
@import "../../components/FreeLoadModal/freeLoadModal.scss";
@import "../../components/NoteModal/noteModal.scss";
@import "../../components/CostModal/costModal.scss";
@import "../../components/UserForm/index.scss";
@import "../../components/ClientForm/index.scss";
@import "../../components/LabeledItem/index.scss";
@import "../../components/ScrollToSection/scroll-to-section.scss";

// Pages
@import "../login/index.scss";
@import "../default/index.scss";
@import "../requests/index.scss";
@import "../order/index.scss";
@import "../profile/index.scss";
@import "../clients/index.scss";
@import "../users/index.scss";

.main-page {
  font-family: "Montserrat", sans-serif !important;

  &__container {
    min-height: 100vh;
    position: relative;
  }

  &__content {
    padding-bottom: 50px;
  }

  &__cards {
    display: flex;
    margin: 50px 150px;
  }
}

.spinner {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
