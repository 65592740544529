.title-body-section {
  font-family: "Montserrat", sans-serif;
  padding-top: 75px;
  max-width: 1200px;
  margin: 0 auto;

  &.align-center {
    text-align: center;
  }
  &.align-left {
    text-align: left;
  }
  &.align-right {
    text-align: right;
  }

  &--bullets-list {
    padding-top: 30px;
  }

  .main-text {
    font-size: 20px;
    padding-top: 20px;
  }
}

@media (min-width: 1024px) {
  .chakra-text {
    font-size: 20px;
  }
}