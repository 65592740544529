.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  position: relative;
}

.login-logo {
  display: flex;
  height: 130px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 15%;

  img {
    height: 65px;
  }

  .logo-name {
    font-family: "Batangas";
    text-transform: uppercase;
    font-size: 70px;
    margin-left: 6px;
    color: #338dbf;
  }
}

.login-form {
  max-width: 400px;
  width: 100%;
  padding: 0 30px;

  .css-x0qqll {
    margin-top: 0;
  }

  .button {
    background-color: #338dbf;
    color: white;
  }
}
