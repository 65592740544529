.image-text-section {
  margin: 60px 25px 20px;

  .image-text {
    &--container {
      margin: 0 20px;

      &.container-inverted {
        flex-direction: row-reverse;
        max-width: 1200px;
        margin: 0 auto;
        justify-content: space-between;

        .image-text--content {
          text-align: right;
          padding-right: 70px;
          padding-left: 0;
        }
      }
    }

    &--heading {
      text-align: center;
      margin-bottom: 25px;
      max-width: 1200px;
      margin: 0 auto;

      &.align-left {
        text-align: left;
      }
      &.align-right {
        text-align: right;
      }
    }

    &--image {
      width: 330px;
      margin: 0 auto;
    }

    &--content {
      margin-top: 20px;
      text-align: center;
      max-width: 650px;
      margin: 0 auto;

      p {
        line-height: 1.7;
      }
    }

    &--bullets-list {
      padding-top: 20px;
    }

    &--button {
      margin-top: 30px;
      background-color: #338dbf;
    }
  }

  .chakra-heading.image-text--heading {
    font-family: "DM Sans", sans-serif;
  }
}

@media (min-width: 1024px) {
  .image-text-section {
    padding: 50px 25px;

    .image-text {
      &--container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }

      &--content {
        text-align: left;
        margin: 0;
        max-width: unset;
        padding-left: 70px;

        p {
          max-width: 570px;
        }

        p,
        ul li {
          font-size: 20px;
        }
      }

      &--heading {
        margin-bottom: 45px;
      }

      &--image {
        width: 550px;
        height: auto;
        margin: 0;
      }
    }
  }
}