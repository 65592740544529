.order-container {
  font-family: "Montserrat", sans-serif;

  .order-heading {
    text-align: center;
    font-family: "Montserrat", sans-serif !important;
    color: #a0aec0;
  }

  .order-card {
    margin: 12px 30px;

    &-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .heading-order-code {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }

      & .chakra-menu__menu-button {
        position: absolute;
        right: 15px;
      }

      input {
        width: 120px;
        margin-left: 5px;
      }

      .chakra-badge,
      .chakra-checkbox {
        margin-right: 10px;
      }
    }
  }

  .top-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 20px;

    .chakra-heading {
      font-family: "Montserrat", sans-serif !important;
      margin-bottom: 15px;
      color: #a0aec0;
      text-transform: uppercase;
    }

    .services-checkbox {
      font-weight: bold;
      color: #006fb7;
    }
  }

  .main-content {
    display: flex;

    .row-heading {
      padding-bottom: 10px;
    }

    .row-top-element {
      margin-bottom: 6px;
    }

    .chakra-heading {
      font-family: "Montserrat", sans-serif !important;
      margin: 20px 0 10px;
      color: #006fb7;
    }

    .left-column {
      width: 20vw;

      .second-row {
        margin-top: 10px;
        .title {
          display: flex;
          align-items: center;
        }

        .add-doc-icon {
          background-color: #38a169;
          border-radius: 20%;
          color: white;
          margin-left: 10px;
        }
      }
    }

    .right-column {
      width: 65vw;
      margin-left: 20px;
      display: block;

      .first-row {
        &-stack {
          display: grid;
          grid-template-columns: auto auto repeat(2, 1fr);
        }
      }

      .second-row {
        margin-top: 5px;
        .right-side {
          margin-left: 15px;
          padding-top: 10px;
        }
      }

      .third-row {
        .subtitle {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
        }
      }
    }
  }

  .chakra-react-select__menu-portal {
    z-index: 100;
  }
}
