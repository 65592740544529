@font-face {
  font-family: "Batangas";
  src: url("../../fonts/Batangas-Bold-700.otf");
}

@import "../../pages/main/main.scss";

body {
  margin: 0;
  height: 100vh
}
